import { observable, computed, action, decorate } from 'mobx';
var store = {
  steps: [],
  stepPoints: [],
  defaultPoints: 10,
  currentStepIndex: 0,
  baseVolume: 70,
  userHasSignificantHearingLoss: false,
  progressBarSteps: 0,
  currentProgressBarStepIndex: 0,
  currentHzIndex: 0,
  get nextStepIndex() {
    return this.currentStepIndex < this.steps.length - 1 ? this.currentStepIndex + 1 : null;
  },
  get currentStep() {
    return this.steps[this.currentStepIndex];
  },
  get points() {
    return this.stepPoints.length ? this.stepPoints.reduce(function (total, points) {
      return total + points;
    }) : 0;
  },
  gotoStep: function gotoStep(stepIndex) {
    this.currentStepIndex = stepIndex;
    if (this.steps[this.currentStepIndex].StepViewType !== 'ButtonResponse') {
      this.currentProgressBarStepIndex++;
    }
  },
  setSteps: function setSteps(theSteps) {
    this.steps = theSteps;
    this.countProgressBarSteps();
  },
  countProgressBarSteps: function countProgressBarSteps() {
    var stepsNum = 0;
    var buttonResponseStepWasCounted = false;
    var HzGroupsInTotal = 3;
    this.steps.map(function (step) {
      if (step.StepViewType === "Tone") {
        stepsNum = stepsNum + HzGroupsInTotal;
      } else if (step.StepViewType === "ButtonResponse") {
        if (!buttonResponseStepWasCounted) {
          stepsNum++;
          buttonResponseStepWasCounted = true;
        }
      } else {
        stepsNum++;
      }
    });
    this.progressBarSteps = stepsNum;
  },
  setProgressBarStep: function setProgressBarStep(HzIndex) {
    if (this.currentHzIndex !== HzIndex && this.currentHzIndex !== 0) {
      this.currentProgressBarStepIndex++;
    }
    this.currentHzIndex = HzIndex;
  },
  addPoints: function addPoints(points) {
    this.stepPoints[this.currentStepIndex] = points;
  },
  setDefaultPoints: function setDefaultPoints(points) {
    this.defaultPoints = points;
  },
  setBaseVolume: function setBaseVolume(volume) {
    this.baseVolume = volume;
  },
  setHasSignificantHearingLoss: function setHasSignificantHearingLoss() {
    this.userHasSignificantHearingLoss = true;
  }
};
decorate(store, {
  steps: observable,
  stepPoints: observable,
  defaultPoints: observable,
  currentStepIndex: observable,
  baseVolume: observable,
  userHasSignificantHearingLoss: observable,
  progressBarSteps: observable,
  currentProgressBarStepIndex: observable,
  currentHzIndex: observable,
  points: computed,
  nextStepIndex: computed,
  currentStep: computed,
  gotoStep: action,
  setSteps: action,
  addPoints: action,
  setDefaultPoints: action,
  setBaseVolume: action,
  setHasSignificantHearingLoss: action,
  setProgressBarStep: action
});
export default store;