import { TweenLite } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
(function smoothScroll($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    var anchorPage = $('.two-section-grid-anchorlinks');
    var anchorLinks = $('a[href*="#"]').not('[href="#"]').not('[href="#0"]');
    var backToTopLink = $('.backToTop-link');
    var header = $('#header');
    var footer = $('#footer');
    var headerHeight = header.outerHeight();
    var footerHeight = footer.outerHeight();
    if (!anchorPage.length || ScrollToPlugin === undefined) {
      return;
    }
    DGS.Resizer.getInstance().register(function () {
      headerHeight = header.outerHeight();
      footerHeight = footer.outerHeight();
    });
    DGS.Scroller.getInstance().register(function (scrollTop) {
      var isBottomOfNav = scrollTop + $(window).height() > $(document).height() - footerHeight;
      if (scrollTop >= headerHeight && !isBottomOfNav) {
        backToTopLink.fadeIn(200);
      } else {
        backToTopLink.fadeOut(200);
      }
    });
    anchorLinks.on('click', function clickAnchor(event) {
      var target = $(this.hash);
      target = target.length ? target : $("[name=".concat(this.hash.slice(1), "]"));
      if (target.length) {
        event.preventDefault();
        TweenLite.to(window, 0.5, {
          scrollTo: {
            y: target,
            offsetY: headerHeight + 100
          }
        });
      }
    });
  });
})(jQuery, window.DGS);