require('core-js');require('regenerator-runtime/runtime');var $ = require('jquery');
window.jQuery = $;
window.$ = $;

// Foundation
require('../../../wdh-foundation/dgs/dgs-api.js');
require('../../../wdh-foundation/dgs/dgs-events.js');
require('@demant/megamenu');
require('../../../wdh-foundation/dgs-utils/dgs-utils.js');
require('@demant/burgermenu');
require('jquery-nice-select/js/jquery.nice-select.min.js');
require('@demant/legacy/googlemaps');
require('../../../wdh-feature/wdh-wffm/src/js/webforms-for-marketers-api.js');

// Feature
// require('./feature/wdh-locations-spot');
require('./feature/data-hierarchy-spot.js');
require('./feature/cookies.js');
require('./feature/form.js');
require('./feature/image-spot.js');
require('./feature/intro-banner.js');
require('./feature/rich-text.js');
require('./feature/search.js');
require('./feature/submenu-point-spot.js');
require('./feature/text-image-spot.js');
require('./feature/wdh-carousel.js');
require('./feature/custom-blog.js');
require('./feature/custom-blog-list.js');
require('./feature/custom-form-planpage.js');
require('./feature/custom-locations-spot.js');
require('./feature/smooth-scroll.js');
require('./feature/custom-accordion-spot.js');
require('./feature/hearing-test');
require('./feature/csea-modal');

// Project
require('./project/main.js');
require('./project/custom-header.js');