/* eslint-disable no-console */
import PropTypes from 'prop-types';
var settings = PropTypes.shape({
  Steps: PropTypes.array.isRequired
});
var defaultSettings = {};
var components = PropTypes.arrayOf(PropTypes.element);
var defaultComponents = function defaultComponents() {
  return [];
};
var component = PropTypes.element;
var layoutType = PropTypes.oneOf(['mobile', 'desktop']);
var defaultLayoutType = 'desktop';
export default {
  settings: settings,
  defaultSettings: defaultSettings,
  components: components,
  defaultComponents: defaultComponents,
  component: component,
  layoutType: layoutType,
  defaultLayoutType: defaultLayoutType
};