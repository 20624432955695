(function ($, DGS) {
  'use strict';

  function swapBlogImage() {
    if (window.location.pathname !== '/blog') {
      return;
    }
    var blogListItems = Array.from(document.querySelectorAll('#blog-list li > div'));
    for (var _i = 0, _blogListItems = blogListItems; _i < _blogListItems.length; _i++) {
      var item = _blogListItems[_i];
      var image = item.children[2];
      item.insertBefore(image, item.childNodes[0]);
    }
  }
  DGS.OnLoad.getInstance().register(function () {
    if ($('#blogList').length) {
      var list = document.querySelector('.blog-list');
      var sectionOne = document.querySelector('.section-one');
      sectionOne.appendChild(list);
    }
    console.log($('#blog-list > li').length);
    if ($('#blog-list > li').length === 0) {
      $('.bloglist').prepend('<p><b>End of list:</b> There are no more blog posts to display. <br/>Please use the previous results link or return to the <a href="/blog">blog</a> home page.</p>');
    }
    swapBlogImage();
  });
})(jQuery, window.DGS);