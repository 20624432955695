var STEP_TYPE_BUTTON_RESPONSE = 'ButtonResponse';
var STEP_TYPE_SLIDER_RESPONSE = 'SliderResponse';
var STEP_TYPE_ENVIRONMENT = 'Environment';
var STEP_TYPE_ENVIRONMENT_INTRO = 'EnvironmentIntro';
var STEP_TYPE_INFO = 'Info';
var STEP_TYPE_TONE_CALIBRATION_INTRO = 'ToneCalibrationIntro';
var STEP_TYPE_TONE_CALIBRATION_PLAY_TEST_SOUND = 'ToneCalibrationPlayTestSound';
var STEP_TYPE_TONE_CALIBRATION_ADJUST_VOLUME = 'ToneCalibrationAdjustVolume';
var STEP_TYPE_TONE_START = 'ToneStart';
var STEP_TYPE_TONE = 'Tone';
var GTM_EVENT = 'PageView';
var GTM_CATEGORY = 'pure tone hearing test';
var GTM_ACTION = 'step';
var GTM_QUESTION = 'question [count]';
var GTM_ENVIRONMENT = 'environment [subcount]';
var GTM_ENVIRONMENT_INTRO = 'environment intro';
var GTM_CALIBRATION = 'calibration [count]';
var GTM_TONE_START = 'start tone test';
var GTM_TONE = 'end tone test';
var GTM_RESULT = 'result';
export default {
  STEP_TYPE_BUTTON_RESPONSE: STEP_TYPE_BUTTON_RESPONSE,
  STEP_TYPE_SLIDER_RESPONSE: STEP_TYPE_SLIDER_RESPONSE,
  STEP_TYPE_ENVIRONMENT: STEP_TYPE_ENVIRONMENT,
  STEP_TYPE_ENVIRONMENT_INTRO: STEP_TYPE_ENVIRONMENT_INTRO,
  STEP_TYPE_INFO: STEP_TYPE_INFO,
  STEP_TYPE_TONE_CALIBRATION_INTRO: STEP_TYPE_TONE_CALIBRATION_INTRO,
  STEP_TYPE_TONE_CALIBRATION_PLAY_TEST_SOUND: STEP_TYPE_TONE_CALIBRATION_PLAY_TEST_SOUND,
  STEP_TYPE_TONE_CALIBRATION_ADJUST_VOLUME: STEP_TYPE_TONE_CALIBRATION_ADJUST_VOLUME,
  STEP_TYPE_TONE: STEP_TYPE_TONE,
  STEP_TYPE_TONE_START: STEP_TYPE_TONE_START,
  GTM_EVENT: GTM_EVENT,
  GTM_CATEGORY: GTM_CATEGORY,
  GTM_ACTION: GTM_ACTION,
  GTM_QUESTION: GTM_QUESTION,
  GTM_ENVIRONMENT: GTM_ENVIRONMENT,
  GTM_ENVIRONMENT_INTRO: GTM_ENVIRONMENT_INTRO,
  GTM_CALIBRATION: GTM_CALIBRATION,
  GTM_TONE: GTM_TONE,
  GTM_TONE_START: GTM_TONE_START,
  GTM_RESULT: GTM_RESULT
};