(function ($, DGS) {
  'use strict';

  DGS.OnLoad.getInstance().register(function () {
    if ($('#blog').length) {
      var content = document.querySelector('.blog > .component-content');
      var $blogHeader = $("<div class='blog-header'></div>");
      var image = document.querySelector('.blog-image');
      var title = document.querySelector('.blog-title');
      var date = document.querySelector('.blog-date');
      var time = document.querySelector('.blog-reading-time');
      // const author = document.querySelector('.blog-author');
      // const authorLink = document.querySelector('#linkAuthor');

      // CONVERT DATE
      var dateString = date.innerHTML.trim();
      var dateArray = dateString.split('/');
      var formattedDate = "".concat(dateArray[1], "/").concat(dateArray[0], "/").concat(dateArray[2]);
      date.innerHTML = formattedDate;
      $blogHeader.append($(date, $(this))).append($(title, $(this))).append($(time, $(this))).append($(image), $(this));
      $blogHeader.insertBefore($(content, $(this)), null);
    }
  });
})(jQuery, window.DGS);