// eslint-disable-next-line wrap-iife
(function anon($, DGS) {
  var burgerMenuConfig = {
    expanderSelector: '.header-mobile-nav .nav-expander',
    // menuItemsClass: 'text-left',
    groupTwoLastLevels: false
  };
  var megaMenuConfig = {
    hover: true
  };
  DGS.OnLoad.getInstance().register(function () {
    var $mainContainer = $('#wrapper > #header');
    var sectionShown = '';
    var headerSettings = {};
    if ($('#plan-landing-page') && $('#plan-landing-page').length) {
      var $headerTopbar = $('#header > .row .contact-bar');
      var $planHeaderLogoRight = $('#header > .row .section-two .image');
      var $planHeaderLogoLeft = $('#header > .row .section-one .image');
      headerSettings = {
        mainContainer: $mainContainer,
        determineSectionCallback: function determineSectionCallback(section) {
          sectionShown = section;
          return null;
        },
        sections: {
          mobile: {
            containers: [{
              className: 'header-mobile-topbar',
              components: [$headerTopbar && $headerTopbar]
            }, {
              className: 'header-mobile-nav',
              components: [$planHeaderLogoLeft && $planHeaderLogoLeft, $planHeaderLogoRight && $planHeaderLogoRight]
            }]
          },
          desktop: {
            containers: [{
              className: 'header-desktop-topbar',
              components: [$headerTopbar && $headerTopbar]
            }, {
              className: 'header-desktop-nav',
              components: [$planHeaderLogoLeft && $planHeaderLogoLeft, $planHeaderLogoRight && $planHeaderLogoRight]
            }]
          }
        }
      };
    } else if ($('#plan-landing-page-with-nav') && $('#plan-landing-page-with-nav').length) {
      var _$headerTopbar = $('#header .contact-bar');
      var $planHeaderNav = $('#header > .row .navigation');
      var _$planHeaderLogoLeft = $('#header > .row .section-one .image');
      var $planHeaderNavLogoRight = $('#header > .row .section-three .image');
      headerSettings = {
        mainContainer: $mainContainer,
        determineSectionCallback: function determineSectionCallback(section) {
          sectionShown = section;
          return null;
        },
        sections: {
          mobile: {
            containers: [{
              className: 'header-mobile-topbar',
              components: [_$headerTopbar && _$headerTopbar]
            }, {
              className: 'header-mobile-nav',
              components: [_$planHeaderLogoLeft && _$planHeaderLogoLeft, $planHeaderNavLogoRight && $planHeaderNavLogoRight]
            }]
          },
          desktop: {
            containers: [{
              className: 'header-desktop-topbar',
              components: [_$headerTopbar && _$headerTopbar]
            }, {
              className: 'header-desktop-nav',
              components: [_$planHeaderLogoLeft && _$planHeaderLogoLeft, $planHeaderNav && $planHeaderNav, $planHeaderNavLogoRight && $planHeaderNavLogoRight]
            }]
          }
        }
      };
    } else {
      var $headerTopBar = $('#header > .row .contact-bar');
      var $logo = $('#header > .row .image');
      var $menu = $('#header > .row .navigation');
      headerSettings = {
        mainContainer: $mainContainer,
        determineSectionCallback: function determineSectionCallback(section) {
          sectionShown = section;
          return null;
        },
        sections: {
          mobile: {
            containers: [{
              className: 'header-mobile-topbar',
              components: [$headerTopBar]
            }, {
              className: 'header-mobile-nav',
              components: [$logo, $('<div class="nav-expander"><span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span></div>')]
            }, {
              className: 'header-mobile-burgermenu',
              components: [$menu]
            }]
          },
          desktop: {
            containers: [{
              className: 'header-desktop-topbar',
              components: [$headerTopBar]
            }, {
              className: 'header-desktop-nav',
              components: [$logo, $menu]
            }]
          }
        }
      };
    }
    DGS.Builder.build(headerSettings);
    DGS.Event.getInstance().on(DGS.Events.BUILDER_DONE, function (settings) {
      if (settings !== headerSettings) return; // ONLY RUN POST SETUP IF IT'S THE RIGHT BUILDER

      $('#header').addClass('animate');

      // SETUP MEGA MENU
      $('.desktop-section .navigation .component-content > ul').megaMenu(megaMenuConfig);

      // SETUP BURGER MENU
      $('.mobile-section .navigation .component-content > ul').burgerMenu(burgerMenuConfig);

      // REGISTER RESIZER HANDLER
      DGS.Resizer.getInstance().register(function () {
        // ADJUST CONTENT SECTION BY ADDING BOTTOM MARGIN TO HEADER
        var headerHeight = 0;
        if (sectionShown === DGS.MOBILE_LAYOUT) {
          headerHeight = $('#header .header-mobile-nav .inner-container').outerHeight();
          $('.dgs-burger-menu').css('top', headerHeight);
        }
        $('#header').css('margin-bottom', headerHeight);
      });
    });
  });
})(jQuery, window.DGS);