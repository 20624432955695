(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.cookie-warning').insertBefore('#header');
    var cookieWarning = $('.component.cookie-warning');
    var hasContent = cookieWarning.find('.component-content').children().length;
    if (hasContent) {
      cookieWarning.addClass('initialized component-ready');
    }
    // console.log(window.DGS.Cookies.getInstance().readCookie("cookie-warning"));
    $('.cookie-warning .submit').click(function (e) {
      e.preventDefault();
      window.DGS.Cookies.getInstance().createCookie('cookie-warning', 1, 365);
      cookieWarning.remove();
      window.DGS.Event.getInstance().trigger('cookie-warning-submit');
    });
  });
})(jQuery);